
.Home {
  text-align: center;
}

.Home-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .Home-logo {
    animation: Home-logo-spin infinite 5s;
  }
}

.big-image{
  animation: big-image-fade forwards 3s;
  object-fit: cover;
}

.Home-header {
  background-color: #43c282;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.tagline {
  height: 100vh;
  line-height: 100vh;
  text-align: center;
  overflow: visible;
}

.tagline-text {
  display: inline-block;
  position: absolute;
  left:  50%;
  text-align: center;
  top: 50vh;
  transform: translate(-50%, -50%);
  line-height: normal;
  padding-bottom: 150px;
  font-size: 5rem;
  color: white;
  font-family: "Trebuchet MS";
  font-weight: lighter;
}

.Home-link {
  color: #61dafb;
}

@keyframes Home-logo-spin {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}

@keyframes big-image-fade {
  from {
    opacity: 1;
  }
  to {
   opacity: .3;
  }
}

