.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


a {
  color: #b8266f !important;
  font-weight: bold;
}

a:hover {
  opacity: 50% !important;
  transition: 0.3s;
  text-decoration: none !important;
}




@-webkit-keyframes gradient-scroll {
  0%{background-position-x:0 }
  50%{background-position-x:100vw}
  100%{background-position-x:0}
}
@-moz-keyframes gradient-scroll {

  0%{background-position-x:0 }
  50%{background-position-x:100vw}
  100%{background-position-x:0}
}
@keyframes gradient-scroll {

  0%{background-position-x:100vw }
  99.9999%{background-position-x:0}
  100%{background-position-x:100vw}
}

.gradient-text {
  background-image: -webkit-linear-gradient(90deg, #98205cff, #a72365ff, #b8266fff, #ca2a7aff, #d54988ff, #db588fff, #e06796ff, #e6769dff, #eb85a4ff, #e6769dff, #db588fff, #d54988ff, #ca2a7aff, #b8266fff, #a72365ff, #98205cff);
  background-image: -moz-linear-gradient(90deg, #98205cff, #a72365ff, #b8266fff, #ca2a7aff, #d54988ff, #db588fff, #e06796ff, #e6769dff, #eb85a4ff, #e6769dff, #db588fff, #d54988ff, #ca2a7aff, #b8266fff, #a72365ff, #98205cff);
  background-image: linear-gradient(90deg, #98205cff, #a72365ff, #b8266fff, #ca2a7aff, #d54988ff, #db588fff, #e06796ff, #e6769dff, #eb85a4ff, #e6769dff, #db588fff, #d54988ff, #ca2a7aff, #b8266fff, #a72365ff, #98205cff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  -webkit-animation: gradient-scroll 20s linear infinite;
  -moz-animation: gradient-scroll 20s linear infinite;
  animation: gradient-scroll 20s linear infinite;
}

.gradient-scroll-bg {
  background-image: -webkit-linear-gradient(90deg, #a72365ff, #b8266fff, #ca2a7aff, #d54988ff, #ca2a7aff, #b8266fff, #a72365ff);
  background-image: -moz-linear-gradient(90deg, #a72365ff, #b8266fff, #ca2a7aff,  #d54988ff, #ca2a7aff, #b8266fff, #a72365ff);
  background-image: linear-gradient(90deg, #a72365ff, #b8266fff, #ca2a7aff,  #d54988ff, #ca2a7aff, #b8266fff, #a72365ff);

  -webkit-animation: gradient-scroll 30s linear infinite;
  -moz-animation: gradient-scroll 30s linear infinite;
  animation: gradient-scroll 30s linear infinite;
}


