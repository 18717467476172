.footer-loc {
    background-color: white;
    width: 100%;
    vertical-align: center;
    line-height: 100%;
    padding-left: 1vw;
    padding-top: 1vw;
}

.Footer {
    max-width: 100%;
    overflow-x: hidden;
}

.filter-purple {
    filter:invert(15%) sepia(32%) saturate(6834%) hue-rotate(313deg) brightness(94%) contrast(87%);
}
